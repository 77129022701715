import { Injectable, Output, EventEmitter, HostListener } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusAngularService {

  @Output()
  status: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { 
    this.bind();
    window.addEventListener('online',  this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOfflineStatus);
  }

  private bind() {
    this.updateOnlineStatus = this.updateOnlineStatus.bind(this);
    this.updateOfflineStatus = this.updateOfflineStatus.bind(this);
  }

  updateOnlineStatus() {
    this.status.next(true);
  }

  updateOfflineStatus() {
    this.status.next(false);
  }
  
}