import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkStatusAngularService } from '../lib/network-status-angular.service';

@NgModule({
  imports: [
  ],
  declarations: []
})
export class NetworkStatusAngularModule { 
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: NetworkStatusAngularModule,
      providers: [ NetworkStatusAngularService ]
    };
  }
}


